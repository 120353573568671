import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { AuthService, SessionReplayService } from '@haleo-frontend/data-access/services';
import { PopoverModal } from '@haleo-frontend/ui';
import { AppService } from '../services/app.service';
import { LoadingController } from '@ionic/angular';
import { LanguageService } from "@haleo-frontend/data-access/services";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    locale: string;

    constructor(private modalController: ModalController,
                private authService: AuthService,
                private router: Router,
                private loadingCtrl: LoadingController,
                private appService: AppService,
                private sessionReplayService: SessionReplayService,
                private languageService: LanguageService) {

        this.locale = this.languageService.locale;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const errorCodes = [401, 403, 404, 500]

        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {

                if (!(error.error instanceof ErrorEvent) && request.url.indexOf('session-replays') === -1) {

                    this.sessionReplayService.saveSession().subscribe();

                    if (errorCodes.includes(error.status) && this.appService.getAppKey() === 'screener-app') {

                        this.loadingCtrl.dismiss();

                        if (!(error.status == 403 && error.error.message === 'validationRequired') &&
                            !(this.router.url.indexOf('verify-token') >= 0) &&
                            !(error.status == 429 && error.error.message.includes('Too many attempts') && this.router.url.indexOf('verify/expired') >= 0)) {
                            this.router.navigate(['/error/' + this.locale], { queryParams: { s: error.status },  queryParamsHandling: 'merge'});
                        }
                    }
                    else {

                        switch (error.status) {
                            case 401:

                                let hasToken: boolean;
                                return this.authService.hasToken()
                                    .pipe(map(has => hasToken = has),
                                        flatMap(() => this.authService.isAuthenticated()),
                                        flatMap(isAuthenticated => {

                                            if (hasToken && !isAuthenticated) {

                                                const message = this.router.url.indexOf('screener') >= 0 ? 'authenticationExpiredScreener' : 'authenticationExpired';

                                                return fromPromise(this.showError(message))
                                                    .pipe(flatMap(() => throwError(error)));
                                            } else {
                                                return throwError(error);
                                            }
                                        }));
                            case 429:

                                // Custom error handling on sign up page
                                if ((request.url.indexOf('/authenticate/register') >= 0 || request.url.indexOf('/authenticate/request-validation-link') >= 0) &&
                                    this.router.url.indexOf('onboarding/sign-up') >= 0) {
                                    return throwError(error);
                                } else {
                                    return fromPromise(this.showError('tooManyAttempts'))
                                        .pipe(flatMap(() => throwError(error)));
                                }

                            case 500:

                                return fromPromise(this.showError('unknownError'))
                                    .pipe(flatMap(() => throwError(error)));

                            case 0:
                                return fromPromise(Network.getStatus())
                                    .pipe(flatMap(status => status.connected ? throwError(error) : of(undefined)))
                        }
                    }
                }

                return throwError(error);
            })
        );
    }

    private async showError(text: string) {

        const modal = await this.modalController.create({
            component: PopoverModal,
            componentProps: {
                title: text + 'Title',
                description: text + 'Description',
                buttons: [{
                    label: 'close',
                    handler: () => {

                        switch (text) {
                            case 'authenticationExpiredScreener':
                                this.router.navigate(['/screener']);
                                break;
                            case 'authenticationExpired':
                                this.router.navigate(['/onboarding/login']);
                                break;
                        }

                        modal.dismiss();
                    }
                }]
            },
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'popover-modal small'
        });
        await modal.present();
    }
}
