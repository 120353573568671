import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Module } from '@haleo-frontend/data-access/models';
import { AbstractComponent } from '@haleo-frontend/utils';
import { ModuleStatusEnum } from '@haleo-frontend/data-access/enums';

@Component({
    selector: 'haleo-module',
    templateUrl: './module.component.html',
    styleUrls: ['./module.component.scss'],
})
export class ModuleComponent extends AbstractComponent implements OnChanges {

    @Input() module: Module;
    @Input() contentProgression: boolean;
    @Input() mode: string;
    @Output() open: EventEmitter<Module>;

    moduleStatusLocked: string = ModuleStatusEnum.locked;
    moduleStatusJustUnlocked: string = ModuleStatusEnum.justUnlocked;
    contentCount: number;
    contentsCompleted: number;

    constructor() {
        super();
        this.open = new EventEmitter<Module>();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.module && this.contentProgression && this.module.contents) {
            this.contentCount = this.module.contents.length;
            this.contentsCompleted = this.module.contents.filter(content => content.progression === 100).length;
        }
    }
}
