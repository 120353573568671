import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor(private toastController: ToastController) {
    }

    showToast(message: string) {

        this.toastController.create({
            message: message,
            duration: 4000,
            position: 'top'
        }).then(toast => toast.present());
    }
}
