import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as echarts from 'echarts';
import * as moment from 'moment';
import { AssessmentUtils } from '@haleo-frontend/utils';
import { Assessment } from '@haleo-frontend/data-access/models';

@Component({
    selector: 'app-sleep-diary-gantt-chart',
    templateUrl: 'gantt-chart.component.html',
    styleUrls: ['gantt-chart.component.scss']
})
export class GanttChartComponent implements OnChanges {

    @Input() sleepDiaries: Assessment[];
    @Input() workingShift: Assessment;
    @Input() showXAxis = true;
    @Input() xAxisSplit = 12;
    @Input() small = false;

    chartOptions: any;
    min: any;
    max: any;
    stores: any;
    length: number;

    ngOnChanges(changes: SimpleChanges) {

        this.stores = [];

        this.getData(this.sleepDiaries, this.workingShift);
        this.length = this.min.diff(this.max, 'hours') * -1;
        const data: any = this.stores.map((interval: any) => this.timeToData(interval[3], interval[0], interval[1], interval[2]));

        this.chartOptions = {
            backgroundColor: '#F3F6FB',
            grid: {
                top: 10,
                left: -35,
                right: 20,
                bottom: 10,
                containLabel: true
            },
            xAxis: {
                position: 'top',
                axisLine: {show: false},
                axisTick: {show: false},
                min: -2,
                max: this.length + 2,
                splitLine: {
                    lineStyle: {color: '#e4e6f1'}
                },
                splitNumber: this.xAxisSplit,
                axisLabel: {
                    show: this.showXAxis,
                    color: '#12205F',
                    fontSize: 8,
                    fontFamily: 'open_sansregular',
                    formatter: (value: any) => {

                        if (value > 0 && value % 2 === 0) {
                            return this.min.clone()
                                .add(value, 'hours')
                                .format('HH:00');
                        } else if (value === 0) {
                            return this.min.clone()
                                .format('HH:mm')
                        } else {
                            return null;
                        }
                    }
                }
            },
            yAxis: {show: false},
            series: [{
                type: 'custom',
                renderItem: (param: any, api: any) => this.renderItem(param, api, this.small),
                encode: {
                    x: [1, 2],
                    y: 0
                },
                data: data
            }]
        };
    }

    private renderItem(params: any, api: any, small: boolean) {

        const categoryIndex = api.value(0);
        const start = api.coord([api.value(1), categoryIndex]);
        const end = api.coord([api.value(2), categoryIndex]);
        const height = api.size([0, 1])[1] * 0.9;
        const width = end[0] - start[0];
        const pillY = small ? (!categoryIndex ? (start[1] - height + 25) : (start[1] - height / 2)) : (start[1] - height / 2);

        const rectShape: any = echarts.graphic.clipRectByRect({
            x: start[0],
            y: pillY,
            width,
            height
        }, {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height,
        });

        if (rectShape) {
            rectShape.r = 10;
        }

        return rectShape && {
            type: 'rect',
            shape: rectShape,
            style: api.style({
                text: width > 80 ? api.value(3) : '',
                textFill: !categoryIndex ? '#ffffff' : '#12205F',
                fontSize: 10,
                fontWeight: 'bold',
                fontFamily: 'open_sansregular'
            })
        };
    }

    private getData(sleepDiaries: Assessment[], workingShift: Assessment) {

        const colorGradient = new echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0, color: '#fa7876'}, {
            offset: 1,
            color: '#fa7876'
        }]);

        const data: any = [];
        sleepDiaries.forEach(sleepDiary => {

            const startTimeValue = AssessmentUtils.getAnswerValueMultipleKeys(['SD_Q5_SW', 'SD_Q5'], sleepDiary.questions, sleepDiary.answers);
            const endTimeValue = AssessmentUtils.getAnswerValueMultipleKeys(['SD_Q1_SW', 'SD_Q1'], sleepDiary.questions, sleepDiary.answers);
            const date = sleepDiary.version + ' '
            let startTime = moment(date + startTimeValue + ':00');
            const endTime = moment(date + endTimeValue + ':00');

            if (startTime.isAfter(endTime)) {
                startTime.subtract(1, 'day');
            }

            this.findInterval([startTime, endTime, colorGradient, 0]);

            const tookANap = AssessmentUtils.getAnswerValue('SD_Q16_SW', sleepDiary.questions, sleepDiary.answers);

            if (tookANap === '1') {

                const napStartTimeValue = AssessmentUtils.getAnswerValue('SD_Q16.1_SW', sleepDiary.questions, sleepDiary.answers);
                const napDurationValue = AssessmentUtils.getAnswerValue('SD_Q16.2', sleepDiary.questions, sleepDiary.answers);

                const napStartTime = moment(date + napStartTimeValue + ':00');
                const napEndTime = moment(date + napStartTimeValue + ':00');
                napEndTime.add(napDurationValue, 'minutes');

                this.findInterval([napStartTime, napEndTime, colorGradient, 0]);
            }
        });

        if (workingShift) {

            let shifts = AssessmentUtils.getAnswerValue('WS_Q2', workingShift.questions, workingShift.answers);
            let date = AssessmentUtils.getAnswerValue('WS_Q1', workingShift.questions, workingShift.answers);

            if (shifts) {
                shifts = JSON.parse(shifts);

                shifts.forEach((shift: any) => {

                    if (shift.start.length > 5) {
                        const startTime = moment(shift.start, 'YYYY-MM-DD HH:mm:ss');
                        const endTime = moment(shift.end, 'YYYY-MM-DD HH:mm:ss');

                        this.findInterval([startTime, endTime, '#D7E0FA', 1]);

                    } else {
                        const startTime = moment(date + ' ' + shift.start + ':00');
                        const endTime = moment(date + ' ' + shift.end + ':00');

                        this.findInterval([startTime, endTime, '#D7E0FA', 1]);
                    }
                });
            }
        }

        return data;
    }

    private timeToData(category: number, startTime: any, endTime: any, color: any) {

        const label = startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm');

        let start = this.min.diff(startTime, 'minutes') * -1 / 60;
        const end = this.min.diff(endTime, 'minutes') * -1 / 60;

        return {
            value: [category, start, end, label],
            itemStyle: {normal: {color}}
        };
    }

    private getMin(date: any) {

        if (!this.min) {
            this.min = date;
            return;
        }

        if (this.min.isAfter(date)) {
            this.min = date;
        }
    }

    private getMax(date: any) {

        if (!this.max) {
            this.max = date;
            return;
        }

        if (this.max.isBefore(date)) {
            this.max = date;
        }
    }

    private findInterval(interval: any []) {
        this.stores = [...this.stores, interval];

        this.getMin(interval[0]);
        this.getMax(interval[0]);
        this.getMin(interval[1]);
        this.getMax(interval[1]);
    }
}
