export const environment = {
    name: 'dev',
    production: true,
    testing: false,
    api: 'https://api.dev.haleo.ca/api/v1/',
    salesForceDomain: 'https://haleo2--dev.sandbox.lightning.force.com/',
    systemLoadApi: 'https://api-alpha-status.haleo.ca/api/v1/',
    apiDomain: 'api.dev.haleo.ca',
    resourcesUrl:
        'https://haleo-backend-dev-ancmabgwe2hxhgap.z01.azurefd.net/storage/backend-dev/resources/',
    stripeKey: 'pk_test_e03scT46QvaFOtvXW7NFii7U00Z0jzsYXN',
    programKeys: {
        sleepCoachingProgram: 'sleepCoachingProgram',
        cbtiProgram: 'cbtiProgram',
        cbtiShiftWorkProgram: 'cbtiShiftWorkProgram',
        selfGuidedCbtiProgram: 'selfGuidedCbtiProgram',
        cbtiNightmareProgram: 'cbtiNightmareProgram',
        cbtiShiftWorkNightmareProgram: 'cbtiShiftWorkNightmareProgram',
        sleepCoachingNewParentProgram: 'sleepCoachingNewParentProgram',
        cbtiNewParentProgram: 'cbtiNewParentProgram',
    },
    screenerRecommendations: {
        cbtiProgram: 'cbtiProgram',
        cbtiShiftWorkNightmareProgram: 'cbtiShiftWorkNightmareProgram',
        keepGoodWork: 'keepGoodWork',
        sleepCoachingProgram: 'sleepCoachingProgram',
        seeDoctorDontComeBack: 'seeDoctorDontComeBack',
        seeDoctorComeBack: 'seeDoctorComeBack',
        cbtiNightmareProgram: 'cbtiNightmareProgram',
        swsdWaitlist: 'swsdWaitlist',
        cbtiShiftWorkProgram: 'cbtiShiftWorkProgram',
        sleepCoachingProgramNewParent: 'sleepCoachingNewParentProgram',
        cbtiDelayedPhase: 'cbtiDelayedPhase',
    },
    cacheValidity: {
        short: 30000,
    },
    sentryDSN: '',
    amplitudeKey: '',
    firebaseConfig: {
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: '',
    },
    appId: '',
    zoomApiKey: '',
    sleepCountry: { zendeskAccount: '', olark: '' },
    broadcasting: {
        authEndpoint: 'https://api.dev.haleo.ca/broadcasting/auth',
        host: 'broadcast.dev.haleo.ca',
        key: 'joPjwF5LcQp2n4M75OpCKhZH64Xm9Q2746Kk7Zoh8H3PxNymxq9dn1zMKE0D',
        port: 443,
        tls: true,
    },
    growthBookKey: '',
    screenerV3Url: 'https://questionnaire.dev.haleoclinic.com/',
};
