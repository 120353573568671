<form [formGroup]="form" (ngSubmit)="save()">

    <ng-container *ngIf="loading">
        <ion-skeleton-text *ngFor="let property of properties" animated></ion-skeleton-text>
    </ng-container>

    <ng-container *ngIf="!loading">
        <ng-container *ngFor="let inputName of inputNames">

            <ng-container *ngIf="form.controls[inputName]">
                <ion-input
                        *ngIf="inputName != 'birthDate' && inputName != 'country' && inputName != 'province'"
                        class="ion-margin-bottom"
                        [type]="(inputName == 'email' || inputName == 'emailConfirmation')? 'email': 'text'"
                        [minlength]="(inputName == 'email' || inputName == 'emailConfirmation')? '5': '2'"
                        maxlength="50"
                        [email]="(inputName == 'email' || inputName == 'emailConfirmation')"
                        [formControlName]="inputName"
                        [placeholder]="'settings.profile.' + inputName | translate"></ion-input>

                <app-country-list *ngIf="inputName == 'country'"
                                  [countryCode]="form.controls.country.value"
                                  [localeShort]="localeShort"
                                  [placeholder]="'settings.profile.country' | translate"
                                  (changed)="countrySelected($event)"></app-country-list>

                <app-province-list *ngIf="inputName == 'province'"
                                   [provinceCode]="form.controls.province.value"
                                   [countryCode]="form.controls.country.value"
                                   [locale]="locale"
                                   [placeholder]="'settings.profile.province' | translate"
                                   (changed)="provinceSelected($event)"></app-province-list>

                <ion-datetime *ngIf="inputName == 'birthDate'"
                              presentation="date"
                              [locale]="locale"
                              [firstDayOfWeek]="1"
                              [formControlName]="inputName"></ion-datetime>

                <div *ngIf="form.controls[inputName].errors" class="error paragraph-15">
                    {{form.controls[inputName].errors?.api}}
                </div>
                <div *ngIf="form.controls.emailConfirmation?.dirty && form.controls.emailConfirmation.invalid"
                     class="error paragraph-15">
                    {{'features-auth.emailConfirmationError' | translate}}
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ion-button class="ion-margin-top" type="submit" expand="block" [disabled]="form.invalid">
        {{'settings.profile.edit.' + saveButtonText | translate}}
    </ion-button>
</form>
