import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Assessment } from '@haleo-frontend/data-access/models';
import { AssessmentUtils } from '@haleo-frontend/utils';

@Component({
    selector: 'app-sleep-diary-week-gantt-chart',
    templateUrl: 'week-gantt.component.html',
    styleUrls: ['week-gantt.component.scss']
})
export class WeekGanttComponent implements OnChanges {

    @Input() sleepDiaries: Assessment[];
    @Input() workingShifts: Assessment[];
    @Input() small: boolean;

    sleepDiariesByDay: Map<string, any>;
    workingShiftsByDay: Map<string, any>;

    ngOnChanges(changes: SimpleChanges) {

        if (this.sleepDiaries) {
            this.sleepDiariesByDay = this.groupSleepDiariesByDay(this.sleepDiaries);

            if (this.workingShifts) {
                this.workingShiftsByDay = this.groupWorkingShiftsByDay(this.workingShifts);
            }
        }
    }

    private groupSleepDiariesByDay(sleepDiaries: Assessment[]) {

        const map = new Map();
        sleepDiaries.forEach((item) => {
            const collection = map.get(item.version);
            if (!collection) {
                map.set(item.version, [item]);
            } else {
                collection.push(item);
            }
        });

        return map;
    }

    private groupWorkingShiftsByDay(workingShifts: Assessment[]) {

        const map = new Map();
        workingShifts.forEach((workingShift) => {
            const day = AssessmentUtils.getAnswerValue('WS_Q1', workingShift.questions, workingShift.answers);
            map.set(day, workingShift);
        });

        return map;
    }
}
