import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SystemLoadStatus } from '@haleo-frontend/data-access/models';
import { EnvironmentUtils } from '@haleo-frontend/utils';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export class SystemLoadService {

    constructor(private http: HttpClient,
                private router: Router,
                private languageService: LanguageService) {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.check().subscribe();
            }
        });
    }

    check(): Observable<any> {

        const isScreenerSignup = document.location.href.indexOf('screener/sign-up') >= 0;
        const isAppOnboarding = document.location.href.indexOf('onboarding/sign-up') >= 0;

        if (isScreenerSignup || isAppOnboarding) {

            return this.http.get<SystemLoadStatus>(EnvironmentUtils.env.systemLoadApi + 'status')
                .pipe(map(data => {

                        if (data.status === 503) {

                            if (document.location.href.indexOf('fr-ca') >= 0) {
                                this.languageService.set('fr-ca');
                            }

                            const regex = /screener\/sign-up\/([^\/]*)\//i;
                            const result = document.location.href.match(regex);
                            const account = result ? result[1] : null;

                            if (account) {
                                this.router.navigate(['/waiting-room', account]);
                            } else {
                                this.router.navigate(['/waiting-room']);
                            }
                        }
                    }),
                    catchError(() => of()));
        } else {
            return of();
        }
    }

    registerOnScreenerWaitingList(email: string, locale: string, account: string): Observable<any> {
        return this.http.post(EnvironmentUtils.env.systemLoadApi + 'waiting-clients', {
            email,
            locale,
            account,
            source: 'screener'
        });
    }

    registerOnAppWaitingList(pushToken: string, source: string, locale: string): Observable<any> {
        return this.http.post(EnvironmentUtils.env.systemLoadApi + 'waiting-clients', {
            locale,
            pushToken,
            source
        });
    }
}
