import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { LanguageService, SystemLoadService } from '@haleo-frontend/data-access/services';
import {
    EnvironmentUtils,
    GlobalErrorHandler,
    HttpBroadcastInterceptor,
    HttpErrorInterceptor
} from '@haleo-frontend/utils';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule, Storage } from '@ionic/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import player from 'lottie-web';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { LottieModule } from 'ngx-lottie';
import { environment as screenerEnvironment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CustomUrlSerializer } from './shared/services/custom-url.serializer';
import { TranslateHttpLoaderService } from './shared/services/translate-http-loader.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoaderService(http, [
        {prefix: './assets/i18n/strings', suffix: '.json', type: 'frontitude'},
        {prefix: './assets/i18n/', suffix: '.json', type: 'ngx-translate'},
        {prefix: './assets/i18n/features/auth/', suffix: '.json', type: 'ngx-translate'},
        {prefix: './assets/i18n/features/waiting-room/', suffix: '.json', type: 'ngx-translate'},
        {prefix: './assets/i18n/ui/', suffix: '.json', type: 'ngx-translate'}
    ]);
}

export function jwtOptionsFactory(storage: any) {
    return {
        tokenGetter: () => storage.get('access_token').then((value: any) => value),
        allowedDomains: [EnvironmentUtils.env.apiDomain],
        skipWhenExpired: true
    };
}

export function LanguageServiceFactory(languageService: LanguageService) {
    return () => languageService.init();
}

export function SystemLoadFactory(systemLoadInterceptor: SystemLoadService) {
    return () => systemLoadInterceptor.check();
}

export function EnvironmentUtilsFactory() {
    return () => EnvironmentUtils.env = screenerEnvironment
}

export function StorageDriver() {

    if (EnvironmentUtils.env.testing) {
        return {};
    } else {
        return {driverOrder: ['sqlite', 'localstorage']};
    }
}

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RecaptchaV3Module,
        FormsModule,
        IonicModule.forRoot({
            scrollPadding: false,
            scrollAssist: false
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IonicStorageModule.forRoot(StorageDriver()),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Storage]
            }
        }),
        LottieModule.forRoot({player: playerFactory}),
        AppRoutingModule
    ],
    providers: [
        File,
        FileOpener,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        LanguageService,
        SystemLoadService,
        {provide: APP_INITIALIZER, useFactory: EnvironmentUtilsFactory, multi: true},
        {provide: APP_INITIALIZER, useFactory: LanguageServiceFactory, deps: [LanguageService], multi: true},
        {provide: APP_INITIALIZER, useFactory: SystemLoadFactory, deps: [SystemLoadService], multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpBroadcastInterceptor, multi: true},
        {provide: UrlSerializer, useClass: CustomUrlSerializer},
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: screenerEnvironment.recaptchaSiteKey}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
