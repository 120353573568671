import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {

    private defaultSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    /**
     * Encrypted client ID has sometime a = or == which breaks Angular serializer
     */
    parse(url: string): UrlTree {

        if (url.indexOf('access-results/') > -1) {
            url = url.replace(/=/g, '%3D');
        }

        return this.defaultSerializer.parse(url);
    }

    serialize(tree: UrlTree): string {
        return this.defaultSerializer.serialize(tree);
    }
}
