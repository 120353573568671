import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { ConsentForm, Paginated } from '@haleo-frontend/data-access/models';
import { EnvironmentUtils } from '@haleo-frontend/utils';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { flatMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConsentFormService {

    constructor(private http: HttpClient,
                private file: File,
                private fileOpener: FileOpener) {
    }

    createScreenerConsentForm(): Observable<ConsentForm> {
        return this.http.post<ConsentForm>(EnvironmentUtils.env.api + `consent-forms`, {type: 'screener'});
    }

    getConsentFormByType(type: string, locale: string): Observable<ConsentForm> {
        return this.http.get<ConsentForm[]>(EnvironmentUtils.env.api + `consent-forms?type=${type}&locale=${locale}`)
            .pipe(map(data => data[0]));
    }

    getConsentForms(): Observable<ConsentForm[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `consent-forms`)
            .pipe(map(data => data.data));
    }

    getConsentFormsToAccept(): Observable<ConsentForm[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `consent-forms?accepted=0`)
            .pipe(map(data => data.data));
    }

    getAllConsentForms(): Observable<ConsentForm[]> {
        return this.http.get<Paginated>(EnvironmentUtils.env.api + `consent-forms?accepted=1`)
            .pipe(map(data => data.data));
    }

    getConsentForm(uuid: string): Observable<ConsentForm> {
        return this.http.get<ConsentForm>(EnvironmentUtils.env.api + `consent-forms/` + uuid);
    }

    accept(uuid: string): Observable<ConsentForm> {

        const data = {
            op: 'replace',
            path: '/acceptedOn',
            value: moment().format()
        };

        return this.http.put<ConsentForm>(EnvironmentUtils.env.api + `consent-forms/` + uuid, data);
    }

    downloadPdf(consentForm: ConsentForm): Observable<void> {

        const fileName = consentForm.label + '.pdf';
        return this.http.get(EnvironmentUtils.env.api + `consent-forms/${consentForm.uuid}/pdf`, {responseType: 'blob'})
            .pipe(flatMap(blob => fromPromise(this.file.writeFile(this.file.cacheDirectory, fileName, blob, {replace: true}))),
                flatMap(() => fromPromise(this.fileOpener.open(this.file.cacheDirectory + '/' + fileName, 'application/pdf'))));
    }
}
