export const regex = {
    // source: https://developer.salesforce.com/docs/atlas.en-us.noversion.mc-apis.meta/mc-apis/using_regular_expressions_to_validate_email_addresses.htm
    emailValidator: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
    password: /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&?*\-\.])[A-Za-z0-9!@#$%^&?*\-\.]{8,}$/, // Length 8 with a special character
    phone: /\d{10}/,
    postalCode: {
        CA: /^[a-zA-Z]\d[a-zA-Z] ?\d[a-zA-Z]\d$/,
        US: /^\d{5}(-\d{4})?$/
    },
    date: /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/
}
