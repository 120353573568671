import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AbstractComponent } from '@haleo-frontend/utils';

@Component({
    selector: 'app-popover',
    templateUrl: 'popover.modal.html',
    styleUrls: ['popover.modal.scss']
})
export class PopoverModal extends AbstractComponent implements OnInit {

    @Input() image: string;
    @Input() title: string;
    @Input() description: string;
    @Input() translateParameters: any;
    @Input() hideCloseButton: boolean;
    @Input() buttons: Array<{ handler: any, label: string, type: string, color: string }>;
    @Input() onClose: any;
    @Input() authButton: {handler: any, label: string, disable: boolean };
    //
    disable: boolean;

    constructor(private modalController: ModalController) {
        super();
    }

    ngOnInit() {
        if(this.authButton) {
            this.disable = this.authButton.disable;
            setTimeout(() => { this.disable = false }, 10000);
        }
    }

    close() {

        if (this.onClose) {
            this.onClose();
        }

        this.modalController.dismiss();
    }

    handler(button : any) {
        if(button.disable) {
            this.disable = true;
        }
        setTimeout(() => {this.disable = false}, 10000);
        button.handler();
    }
}
