import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';

import { LogUtils } from './log.utils';

declare const amplitude: any;

@Injectable({
    providedIn: 'root'
})
export class TrackingUtils {

    private static platform: Platform;
    private static isWeb: boolean;
    private static isCapacitor: boolean;
    private static amplitudeInstance: any;
    private static firebaseWebEnabled: boolean;

    static init(platform: Platform, amplitudeKey: string, firebaseConfig: any) {

        this.platform = platform;
        this.isWeb = this.platform.is('desktop') || this.platform.is('mobileweb');
        this.isCapacitor = this.platform.is('capacitor');

        this.platform.ready().then(() => {

            if (typeof amplitude !== 'undefined') {
                this.amplitudeInstance = amplitude.getInstance();
                this.amplitudeInstance.init(amplitudeKey);
            }

            if (this.isWeb && firebaseConfig && firebaseConfig.apiKey !== '') {
                firebase.initializeApp(firebaseConfig);
                firebase.analytics();
                firebase.performance();
                this.firebaseWebEnabled = true;
            }
        });
    }

    static trackPageView(pageName: string) {
        TrackingUtils.trackEvent(pageName + '_viewed');
    }

    static trackEvent(name: string, data = {}) {

        this.platform.ready().then(() => {

            if (this.isWeb && this.firebaseWebEnabled) {
                firebase.analytics().logEvent(name, data);
            }

            if (this.isCapacitor) {
                FirebaseAnalytics.logEvent({name, params: data});
            }

            if (this.amplitudeInstance) {
                this.amplitudeInstance.logEvent(name, data);
            }
        });

        LogUtils.debug('TrackEvent', name, data);
    }

    static setUserProperty(name: string, value: string) {

        this.platform.ready().then(() => {

            if (this.isWeb && this.firebaseWebEnabled) {
                const data: any = {name: value};
                firebase.analytics().setUserProperties(data);
            }

            if (this.isCapacitor) {
                FirebaseAnalytics.setUserProperty({name, value});
            }

            if (this.amplitudeInstance) {
                const identify = new amplitude.Identify().set(name, value);
                this.amplitudeInstance.identify(identify);
            }
        });

        LogUtils.debug('SetUserProperty', name, value);
    }
}
