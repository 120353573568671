import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AbstractComponent implements OnDestroy {

    destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

    ngOnDestroy(): void {
        this.destroy.next(null);
    }
}
