import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Client } from '@haleo-frontend/data-access/models';
import { AppService, EnvironmentUtils, LogUtils } from '@haleo-frontend/utils';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    private clientCache: ReplaySubject<Client>;
    private client: Client | null;

    constructor(private http: HttpClient,
                private platform: Platform,
                private deviceService: DeviceDetectorService,
                private appService: AppService,
                private storage: Storage) {

        this.clientCache = new ReplaySubject<Client>(1);
    }

    update(data: any): Observable<Client> {

        return fromPromise(Device.getInfo())
            .pipe(flatMap(device => {

                    const os = this.platform.is('android') ? 'android' : this.platform.is('ios') ? 'ios' : this.deviceService.os.toLowerCase();

                    const context = {
                        device: {
                            model: device.model,
                            os,
                            osVersion: device.osVersion,
                            native: this.platform.is('hybrid'),
                            browser: this.deviceService.browser,
                            browserVersion: this.deviceService.browser_version
                        },
                        version: LogUtils.appVersion,
                        appKey: this.appService.getAppKey()
                    };

                    data.context = JSON.stringify(context);
                    data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                    return this.http.put<Client>(EnvironmentUtils.env.api + 'clients/-1', data);
                }),
                map(client => {
                    this.client = client;
                    LogUtils.setUser(this.client);
                    this.clientCache.next(this.client);
                    return this.client;
                }));
    }

    get(): Observable<Client> {

        if (!this.client) {

            this.http.get<Client>(EnvironmentUtils.env.api + 'clients/-1')
                .subscribe(client => {
                    this.client = client;
                    LogUtils.setUser(this.client);
                    this.clientCache.next(this.client);
                });
        } else {
            this.clientCache.next(this.client);
        }

        setTimeout(() => this.client = null, EnvironmentUtils.env.cacheValidity.short);

        return this.clientCache;
    }

    getClientWithoutCache(): Observable<Client> {
        return this.http.get<Client>(EnvironmentUtils.env.api + 'clients/-1');
    }


    getClientWithStaff(): Observable<Client> {
        return this.http.get<Client>(EnvironmentUtils.env.api + 'clients/-1?embed=staff');
    }

    isDesjardins(): Observable<boolean> {
        return this.get()
            .pipe(map(client => client.account.key === 'desjardins'));
    }

    async updateAppVersion() {

        if ((this.platform.is('android') || this.platform.is('ios')) &&
            await this.storage.get('appVersionCache') !== LogUtils.appVersion) {
            await this.storage.set('appVersionCache', LogUtils.appVersion);
            await lastValueFrom(this.update({}));
        }
    }

    sendWorkValidationEmail() {
        return this.http.post<void>(EnvironmentUtils.env.api + 'clients/calendly-booked', {});
    }
}
