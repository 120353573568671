import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import { EnvironmentUtils } from '../utils/environment.utils';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private storage: Storage) {
    }

    getAppKey() {

        if (EnvironmentUtils.env.appId.indexOf('sleepcountry') > -1) {
            return 'sleep-country';
        }

        if (EnvironmentUtils.env.appId.indexOf('dormezvous') > -1) {
            return 'dormez-vous';
        }

        if (EnvironmentUtils.env.appId.indexOf('sleepassistant') > -1) {
            return 'sleep-assistant';
        }

        if (EnvironmentUtils.env.appId.indexOf('screener-app') > -1) {
            return 'screener-app';
        }

        if (EnvironmentUtils.env.appId.indexOf('screener') > -1) {
            return 'screener';
        }

        if (EnvironmentUtils.env.api.indexOf('internal') > -1) {
            return 'clinic-portal';
        }

        return 'haleo';
    }

    isSleepCountry() {
        return EnvironmentUtils.env.appId.indexOf('sleepcountry') > -1 ||
            EnvironmentUtils.env.appId.indexOf('dormezvous') > -1;
    }

    isDormezVous() {
        return EnvironmentUtils.env.appId.indexOf('dormezvous') > -1;
    }

    isSelfGuided() {
        return EnvironmentUtils.env.appId.indexOf('sleepcountry') > -1 ||
            EnvironmentUtils.env.appId.indexOf('dormezvous') > -1 ||
            EnvironmentUtils.env.appId.indexOf('sleepassistant') > -1;
    }

    async isDebugEnabled() {
        return await this.storage.get('appDebugMode');
    }

    async enableDebug() {
        return await this.storage.set('appDebugMode', true);
    }

    async disableDebug() {
        return await this.storage.set('appDebugMode', false);
    }
}
