import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Answer, Question } from '@haleo-frontend/data-access/models';
import { LanguageService } from '@haleo-frontend/data-access/services';

@Component({
    selector: 'haleo-picker-date-time-overlay',
    templateUrl: 'picker-date-time-overlay.component.html',
    styleUrls: ['picker-date-time-overlay.component.scss']
})
export class PickerDateTimeOverlayComponent implements OnInit {

    // Question: time
    @Input() question: Question;
    @Input() answer: Answer | undefined;
    @Input() min: any;
    @Input() max: any;
    @Input() defaultValue: any;

    hours: { options: { text: string; value: string, duration?: any, transform?: any }[]; columnWidth: number; selectedIndex: number };
    minutes: { options: { text: string; value: string, duration?: any, transform?: any }[]; columnWidth: number; selectedIndex: number };
    months: { options: { text: string; value: string, duration?: any, transform?: any }[]; columnWidth: number; selectedIndex: number };
    days: { options: { text: string; value: string, duration?: any, transform?: any }[]; columnWidth: number; selectedIndex: number };
    amOrPm = {
        columnWidth: 60,
        selectedIndex: 0,
        options: [
            {
                value: 'am',
                text: 'am'
            },
            {
                value: 'pm',
                text: 'pm'
            }
        ]
    };

    states = {
        hour: '',
        minute: '',
        amOrPm: '',
        month: '',
        day: '',
        year: moment().format('YYYY')
    };
    value: string;
    locale = 'en-ca';

    constructor(private languageService: LanguageService,
                private modalController: ModalController) {

        this.locale = this.languageService.locale;

        const hour12HOptions = this.generateByRange(1, 13);
        const hour24HOptions = this.generateByRange(0, 24);
        const minuteOptions = this.generateByRange(0, 60);

        this.hours = {
            columnWidth: 60,
            options: this.locale === 'en-ca' ? hour12HOptions : hour24HOptions,
            selectedIndex: 0
        };

        this.minutes = {
            columnWidth: 60,
            options: minuteOptions,
            selectedIndex: 0
        };
    }

    ngOnInit() {

        const dayOptions = this.generateDay(this.min, this.max);
        this.states.day = dayOptions[0]['value'];
        this.days = {
            columnWidth: 60,
            options: dayOptions,
            selectedIndex: 0
        };

        const monthOptions = this.generateMonth(this.min, this.max);
        this.states.month = monthOptions[0]['value'];
        this.months = {
            columnWidth: 60,
            options: monthOptions,
            selectedIndex: 0
        };

        let time: any = moment().format('HH:mm');

        if (this.question?.options) {
            time = JSON.parse(this.question.options).defaultValue;
        }

        if (this.answer && this.answer?.value) {
            time = this.answer?.value;
        }

        if (this.defaultValue) {
            const date = moment(this.defaultValue, 'YYYY-MM-DD HH:mm:ss');
            this.months.selectedIndex = this.getIndexOrDefault(this.months.options, date.format('MM'));
            this.days.selectedIndex = this.getIndexOrDefault(this.days.options, date.format('DD'));

            this.states.month = date.format('MM');
            this.states.day = date.format('DD');

            time = date.format('HH:mm');
        }

        if (this.locale === 'en-ca') {
            time = moment(time, 'HH:mm').format('hh:mm:a').split(':');
            this.amOrPm.selectedIndex = this.getIndexOrDefault(this.amOrPm.options, time[2]);
            this.states.amOrPm = time[2];
        } else {
            time = time.split(':');
        }

        this.states.hour = time[0];
        this.states.minute = time[1];

        this.hours.selectedIndex = this.getIndexOrDefault(this.hours.options, time[0]);
        this.minutes.selectedIndex = this.getIndexOrDefault(this.minutes.options, time[1]);
    }

    onChangeHour(value: any) {
        this.states.hour = value;
        this.detectChanges();
    }

    onChangeMinute(value: any) {
        this.states.minute = value;
        this.detectChanges();
    }

    onChangeAmOrPm(value: any) {
        this.states.amOrPm = value;
        this.detectChanges();
    }

    onChangeMonth(value: any) {
        this.states.month = value;
        this.detectChanges();
    }

    onChangeDay(value: any) {
        this.states.day = value;
        this.detectChanges();
    }

    detectChanges() {

        const {hour, minute, amOrPm, day, month, year} = this.states;

        if (!hour || !minute || (this.locale === 'en-ca' && !amOrPm) || !day || !month) {
            return;
        }

        let time = year + ' ' + month + ' ' + day + ' ' + hour + ' ' + minute;
        let format = 'YYYY MM DD hh mm';

        if (this.locale === 'en-ca') {
            time += ' ' + amOrPm;
            format += ' a';
        }

        this.value = moment(time, format).format('YYYY-MM-DD HH:mm:ss');
    }

    async close() {
        await this.modalController.dismiss({questionKey: this.question.key, value: this.value});
    }

    async cancel() {
        await this.modalController.dismiss();
    }

    private getIndexOrDefault(options: any, value: string) {
        const index = options.findIndex((option: any) => option.value === value);
        return index > -1 ? index : 0;
    }

    private generateByRange(lower: number, upper: number) {
        const options = [];
        for (let i = lower; i < upper; i++) {
            const value = i < 10 ? `0${i}` : i.toString();
            options.push({
                value,
                text: value
            });
        }
        return options;
    }

    private generateDay(min: any, max: any) {
        const options = [];
        let start = min;

        while (!start.isAfter(max)) {

            const value = start.format('DD');
            options.push({
                value,
                text: value
            });

            start = start.clone().add(1, 'day');
        }
        return options;
    }

    private generateMonth(min: any, max: any) {
        const options = [];
        let start = min;

        while (!start.isAfter(max)) {

            options.push({
                value: start.format('MM'),
                text: start.format('MMM')
            });

            start = start.clone().add(1, 'month');
        }
        return options;
    }
}
