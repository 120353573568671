import { Directive, HostListener, Input } from '@angular/core';
import { TrackingUtils } from '@haleo-frontend/utils';

@Directive({
    selector: '[track-event]'
})
export class TrackEventDirective {

    @Input('track-event') eventName: string;

    @HostListener('click') onClick() {
        TrackingUtils.trackEvent(this.eventName);
    }
}
