export enum AssessmentTypeEnum {
    screener = 'screener',
    ess = 'ess',
    satisfactionTherapySession = 'satisfaction_therapy_session',
    satisfactionProgram = 'satisfaction_program',
    sleepDiaryAll = 'sleep_diary_all',
    sleepDiary = 'sleep_diary',
    sleepDiaryShiftWork = 'sleep_diary_shift_work',
    sleepDiarySelfGuided = 'sleep_diary_self_guided',
    sleepProfile = 'sleep_profile',
    sleepQ = 'sleepQ',
    postQ = 'postQ',
    postQCbti = 'postQ_satisfaction',
    thoughtRecord = 'thought_record',
    workingShift = 'working_shift',
    screenerSelfGuided = 'screener_self_guided',
    sleepHygiene = 'sleep_hygiene',
    goalSetting = 'goal_setting',
    postQSelfGuided = 'postQ_satisfaction_self_guided',
    postQCbtiShiftWork = 'postQ_satisfaction_cbti_shift_work',
    screenerFollowUp = 'screener_follow_up',
    screenerRevisedB2C = 'screener_revised_b2c'
}
