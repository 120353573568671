import { Pipe, PipeTransform } from '@angular/core';
import * as countries from 'i18n-iso-countries';

@Pipe({name: 'country'})
export class CountryPipe implements PipeTransform {

    transform(countryCode: string, localeShort: string): string {

        countries.registerLocale(require('i18n-iso-countries/langs/' + localeShort + '.json'));

        const countryList = countries.getNames(localeShort);

        if (countryList[countryCode]) {
            return countryList[countryCode];
        } else {
            return '';
        }
    }
}
