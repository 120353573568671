import { environment as base } from '@haleo-frontend/utils';

export const environment = {
    ...base,
    appId: 'ca.haleo.screener-app.dev',
    sentryDSN: 'https://0c5f0cb86920459cabec4f47ef4e0c2c@sentry-relay.haleo.ca/4504323173515264',
    firebaseConfig: {
        apiKey: 'AIzaSyA92sRTcaMOvbiKNNztRI8wvCQ-CQQXWsU',
        authDomain: 'haleo-screener-v3-dev.firebaseapp.com',
        projectId: 'haleo-screener-v3-dev',
        storageBucket: 'haleo-screener-v3-dev.appspot.com',
        messagingSenderId: '473663599184',
        appId: '1:473663599184:web:c1517c14a393532bd31755',
        measurementId: 'G-7T1MKYEKXT'
    },
    recaptchaSiteKey: '6Lep_0EnAAAAAKvxim2UZcXDQqd_7oUO6oIa1QzJ',
};
