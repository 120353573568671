<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="start" *ngIf="!hideCloseButton">
            <ion-button (click)="close()" color="light">
                <ion-icon slot="icon-only" class="icon haleo-close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-text-center ui-2022">

    <img *ngIf="image" [src]="'assets/img/popover/'+image+'.svg'"/>

    <h1 class="hero-title" [innerHTML]="'ui.popover.' + title | translate:translateParameters"></h1>
    <p class="paragraph-15" *ngIf="description"
       [innerHTML]="'ui.popover.' + description | translate:translateParameters"></p>

</ion-content>
<ion-footer class="ion-no-border">
    <ion-toolbar>
        <ion-buttons *ngIf="buttons && buttons.length > 0">
            <ion-button *ngFor="let button of buttons"
                        [color]="button.color? button.color: 'secondary'"
                        class="ion-text-uppercase"
                        [class.btn-link]="!button.type"
                        [fill]="button.type"
                        (click)="button.handler()">
                {{'ui.popover.' + button.label | translate}}
            </ion-button>
        </ion-buttons>
        <ion-buttons *ngIf="authButton">
            <ion-button class="ion-text-center text-button"
                        fill="clear"
                        (click)="handler(authButton)"
                        [disabled]="disable">
                {{'ui.popover.' + authButton.label | translate}}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
