<ion-card *ngIf="module"
          [class]="'module '+mode"
          mode="ios"
          (click)="open.emit(module)">
    <img [src]="module.imageUrl" loading="lazy" [alt]="module.label"/>
    <div class="status locked link-small" *ngIf="module.status == moduleStatusLocked">
        <ion-icon class="icon haleo-lock"></ion-icon>
        {{'module.status.' + module.status | translate}}
    </div>
    <div class="status unlocked link-small" *ngIf="module.status == moduleStatusJustUnlocked">
        <ion-icon class="icon haleo-unlock"></ion-icon>
        {{'module.status.' + module.status | translate}}
    </div>
    <h3 class="card-title"
        *isDebug="false"
        [class.with-progression]="contentProgression">{{module.label}}</h3>
    <h3 class="card-title"
        *isDebug="true"
        [class.with-progression]="contentProgression">{{module.key}}</h3>
    <p *ngIf="contentProgression && contentCount" class="paragraph-13">
        {{'module.contentProgression' | translate:{contentsCompleted: contentsCompleted, contentCount: contentCount} }}
    </p>
</ion-card>

<ion-card *ngIf="!module" mode="ios" class="skeleton">
    <h3 class="card-title">
        <ion-skeleton-text animated></ion-skeleton-text>
    </h3>
</ion-card>
