import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { EnvironmentUtils } from '@haleo-frontend/utils';
import { FullpagePopoverModal } from '@haleo-frontend/ui';

@Injectable({
    providedIn: 'root'
})
export class feedbackService {

    constructor(private http: HttpClient,
                private modalController: ModalController,
                private router: Router) {
    }

    sendFeedback(data: any): Observable<void> {
        return this.http.post<void>(EnvironmentUtils.env.api + `send-feedback`, {
            topic: data.topic,
            comments: data.comments
        });
    }

    contactSupport(data: any): Observable<void> {
        return this.http.post<void>(EnvironmentUtils.env.api + `contact-support`, {
            topic: data.topic,
            comments: data.comments
        });
    }

    async feedbackLimitModal() {
        const modal = await this.modalController.create({
            component: FullpagePopoverModal,
            componentProps: {
                title: 'feedbackTitle',
                description: 'feedbackDescription',
                icon: 'ph-chat-teardrop-dots-bold',
                buttons: [
                    {
                        label: 'feedbackContactSupport',
                        type: 'solid',
                        handler: () => {
                            this.router.navigate(['/contact-support']);
                            modal.dismiss();
                        },
                    },
                    {
                        label: 'maybeLater',
                        type: 'clear',
                        handler: () => {
                            modal.dismiss()
                        },
                    }]
            },
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'ui-2022'
        });
        await modal.present();
    }
}
