import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as Rrweb from 'rrweb';
import { catchError, flatMap } from 'rxjs/operators';
import { EnvironmentUtils } from '@haleo-frontend/utils';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class SessionReplayService {

    private events: any[];
    private stop: any;

    constructor(private http: HttpClient,
                private authService: AuthService) {
    }

    init() {

        if (this.stop) {
            this.stop();
        }

        this.events = [];
        const me = this;

        this.stop = Rrweb.record({
            emit(event: any) {
                me.events.push(event);
            },
            sampling: {
                scroll: 150, // do not emit twice in 150ms,
            }
        });
    }

    saveSession(): Observable<any> {

        const events = this.localAssetsRewrite(JSON.stringify(this.events));
        this.init();

        return this.authService.isAuthenticated()
            .pipe(flatMap(isAuthenticated => {

                    if (EnvironmentUtils.env.testing) {
                        return of();
                    }

                    if (isAuthenticated) {
                        return this.http.post(EnvironmentUtils.env.api + 'session-replays', {events});
                    } else {
                        return this.http.post(EnvironmentUtils.env.api + 'anonymous-session-replays', {events});
                    }
                }),
                catchError(() => of())); // Silence any error
    }

    private localAssetsRewrite(events: string) {

        const regex = /http:\/\/localhost[:0-9]*/ig;
        return events?.replace(regex, 'https://evaluation.haleoclinic.com');
    }
}
