import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@haleo-frontend/data-access/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../services/app.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService,
                private router: Router,
                private appService: AppService) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.authService.isAuthenticated().pipe(map(isAuthenticated => {

            if (!isAuthenticated) {

                if (state.url.indexOf('screener') >= 0) {
                    this.router.navigate(['/screener']);
                } else if (state.url.indexOf('assessment') >= 0 && this.appService.getAppKey() === 'screener') {
                    this.router.navigate(['/sign-up']);
                } else if (next.data.anAuthRoute) {
                    this.router.navigate([next.data.anAuthRoute]);
                } else {
                    this.router.navigate(['/onboarding/start']);
                }
                return false;
            }

            return true;
        }));
    }
}
