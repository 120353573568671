import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppService } from '@haleo-frontend/utils';

@Directive({
    selector: '[isDebug]'
})
export class IsDebugDirective {

    constructor(private viewContainerRef: ViewContainerRef,
                private templateRef: TemplateRef<any>,
                private appService: AppService) {
    }

    @Input()
    set isDebug(status: boolean) {

        this.viewContainerRef.clear();

        this.appService.isDebugEnabled()
            .then(isDebugEnabled => {

                if (status && isDebugEnabled) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                }

                if (!status && !isDebugEnabled) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                }
            });
    }
}
