import { Injectable } from '@angular/core';
import { AppService } from '@haleo-frontend/utils';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import * as countries from 'i18n-iso-countries';
import * as moment from 'moment';
import { lastValueFrom, of, Subject } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

import { ClientService } from './client.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    locale: string;
    localeShort: string;
    isChanged: boolean;
    localeChangeEvent: Subject<null>;

    constructor(private translate: TranslateService,
                private clientService: ClientService,
                private authService: AuthService,
                private storage: Storage,
                private appService: AppService) {
    }

    async init(): Promise<any> {

        this.locale = await this.storage.get('locale');

        if (!this.locale) {

            if (this.translate.getBrowserLang() === 'fr') {
                this.locale = 'fr-ca';
                this.localeShort = 'fr';
            } else {
                this.locale = 'en-ca';
                this.localeShort = 'en';
            }

            if (this.appService.isDormezVous()) {
                this.locale = 'fr-ca';
                this.localeShort = 'fr';
            }
        }
        this.localeChangeEvent = new Subject<null>();

        return this.set(this.locale);
    }

    set(locale: string): Promise<any> {

        this.isChanged = this.locale !== locale;
        this.locale = locale;

        if (this.locale === 'en-ca') {
            this.localeShort = 'en';
        } else {
            this.localeShort = 'fr';
        }

        this.translate.setDefaultLang(this.locale);
        this.translate.use(this.locale);
        moment.locale(this.localeShort);

        countries.registerLocale(require('i18n-iso-countries/langs/' + this.localeShort + '.json'));

        this.storage.set('locale', this.locale);
        this.localeChangeEvent.next(null);

        let isAuthenticated: boolean, isCaseManager: boolean;
        return lastValueFrom(this.authService.isAuthenticated()
            .pipe(map(value => isAuthenticated = value),
                flatMap(() => this.authService.isCaseManager()),
                map(value => isCaseManager = value),
                flatMap(() => this.authService.isScopedToClientAssessments()),
                flatMap(isScopedToAssessments => {

                    // Prevent language change on sign-up page and use document (instead of router) because of cyclic dependencies
                    if (!isCaseManager && !isScopedToAssessments && isAuthenticated && document.location.pathname.indexOf('screener/sign-up') < 0 &&
                        !(this.appService.getAppKey() === 'screener-app' && (document.location.pathname.includes('home') || document.location.pathname.includes('sign-up')))) {

                        // Silence error to be able to load the app when server is down
                        return this.clientService.update({locale: this.locale})
                            .pipe(catchError(() => of(null)));
                    } else {
                        return of(null);
                    }
                })));
    }

    getCountries() {
        return countries.getNames(this.localeShort);
    }

    resetIsChanged() {
        this.isChanged = false;
    }
}
