import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PickerController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService, CountryUtils } from '@haleo-frontend/utils';

@Component({
    selector: 'app-province-list',
    templateUrl: 'province-list.component.html',
    styleUrls: ['province-list.component.scss']
})
export class ProvinceListComponent implements OnChanges {

    @Input() provinceCode: string;
    @Input() countryCode: string;
    @Input() locale: string;
    @Input() placeholder: string;
    @Output() changed: EventEmitter<string>;

    provinceList: any;
    appKey: string;

    constructor(private pickerCtrl: PickerController,
                private translateService: TranslateService,
                private appService: AppService) {
        this.changed = new EventEmitter<string>();
        this.appKey = this.appService.getAppKey();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.countryCode && this.locale) {
            this.provinceList = null;

            switch (this.countryCode) {
                case 'CA' :
                    this.provinceList = (<any>CountryUtils.provinces)[this.locale];
                    break;
                case 'US' :
                    this.provinceList = CountryUtils.states;
            }
        }
    }

    async select() {

        const options = Object.keys(this.provinceList)
            .map(code => ({
                text: this.provinceList[code],
                value: code
            }));

        // Bug fix: impossible to select first value
        options.unshift({text: '', value: ''});

        const config = {
            buttons: [{
                text: this.translateService.instant('cancel'),
                role: 'cancel'
            }, {text: this.translateService.instant('done')}],
            columns: [{
                name: 'data',
                options: options
            }]
        };

        const picker = await this.pickerCtrl.create(config);
        await picker.present();
        await picker.onDidDismiss();

        const col = await picker.getColumn('data');

        if (col && col.selectedIndex) {
            const selectedOption: any = (<any>col.options)[col.selectedIndex];
            this.changed.emit(selectedOption.value);
        }
    }
}
