import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionReplayService } from '@haleo-frontend/data-access/services';

import { EnvironmentUtils } from './utils/environment.utils';
import { TrackingUtils } from './utils/tracking.utils';
import { LogUtils } from './utils/log.utils';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    constructor(private translateService: TranslateService,
                private sessionReplayService: SessionReplayService) {
        super();
    }

    handleError(error: any) {
        super.handleError(error);

        if (error.name !== 'HttpErrorResponse') {

            const chunkFailedMessage = /Loading chunk [\d]+ failed/;

            if (chunkFailedMessage.test(error.message)) {
                TrackingUtils.trackEvent('chunk_loading_failed');
                alert(this.translateService.instant('ui.popover.chunkLoadingFailed'));
                setTimeout(() => window.location.reload(), 4000);
            } else {

                if (EnvironmentUtils.env.sentryDSN) {
                    try {
                        LogUtils.captureException(error.originalError || error.error || error);
                    } catch (e) {
                        console.error(e);
                    }
                }

                this.sessionReplayService.saveSession().subscribe();
            }
        }
    }
}
