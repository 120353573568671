import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService, LanguageService } from '@haleo-frontend/data-access/services';
import { AbstractComponent, EqualsValidator, ErrorService, regex } from '@haleo-frontend/utils';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-client-edit',
    templateUrl: 'client-edit.component.html',
    styleUrls: ['client-edit.component.scss']
})
export class ClientEditComponent extends AbstractComponent implements OnInit {

    @Input() properties: string[];
    @Input() saveButtonText: string;
    @Output() saved: EventEmitter<{ loading: any }>;

    form: FormGroup;
    loading: boolean;
    countryList: any;
    localeShort: string;
    locale: string;
    inputNames: string[];

    constructor(private formBuilder: FormBuilder,
                private clientService: ClientService,
                private errorService: ErrorService,
                private loadingCtrl: LoadingController,
                private languageService: LanguageService) {
        super();
        this.saved = new EventEmitter<{ loading: any }>();
    }

    ngOnInit(): void {

        this.loading = true;
        this.localeShort = this.languageService.localeShort;
        this.locale = this.languageService.locale;
        this.form = this.formBuilder.group({});

        if (this.properties.findIndex(property => property === 'country') >= 0) {
            this.countryList = this.languageService.getCountries();
        }

        this.clientService.get()
            .pipe(takeUntil(this.destroy))
            .subscribe((client: any) => {

                const formControls: any = {};
                this.properties.forEach(property => {

                    if (property === 'province') {

                        if (client.country === 'CA' || client.country === 'US') {
                            formControls[property] = new FormControl(client[property], Validators.required);
                        }
                    } else if (property === 'email') {
                        formControls[property] = new FormControl(client[property], [Validators.required, Validators.pattern(regex.emailValidator)]);
                        formControls[property + 'Confirmation'] = new FormControl(client[property], Validators.required);
                    } else {

                        const value = property == 'lastName' && client.lastName?.toLowerCase() === client.email?.toLowerCase() ? '' : client[property];
                        formControls[property] = new FormControl(value, Validators.required);
                    }
                });

                this.inputNames = Object.keys(formControls);

                if (formControls['emailConfirmation']) {

                    this.form = this.formBuilder.group(formControls,
                        {
                            validator: EqualsValidator('email', 'emailConfirmation')
                        });
                } else {

                    this.form = this.formBuilder.group(formControls);
                }

                this.loading = false;
            });
    }

    countrySelected(countryCode: string) {

        this.form.controls.country.setValue(countryCode);

        if (countryCode === 'CA' || countryCode === 'US') {
            if (this.form.controls.province) {
                this.form.controls.province.setValue(null);
            } else {
                this.form.addControl('province', new FormControl('', Validators.required));
            }
        } else {
            this.form.removeControl('province');
        }

        this.inputNames = Object.keys(this.form.controls);
    }

    provinceSelected(countryCode: string) {
        this.form.controls.province.setValue(countryCode);
    }

    async save() {

        const loading = await this.loadingCtrl.create();
        await loading.present();

        if (this.form.value.birthDate) {
            this.form.value.birthDate = moment(this.form.value.birthDate).format('YYYY-MM-DD');
        }

        try {

            if (!this.form.value.province) {
                this.form.value.province = null;
            }

            await lastValueFrom(this.clientService.update(this.form.value)
                .pipe(takeUntil(this.destroy)));

            this.saved.emit({loading});

        } catch (exception: any) {

            if (exception.status === 422) {

                Object.keys(exception.error.errors).forEach(field => {
                    const control: AbstractControl = this.form.controls[field];
                    control.setErrors({api: exception.error.errors[field][0]});
                });
            }

            await loading.dismiss();
        }

    }
}
