import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrackEventDirective } from './directives/track-event.directive';
import { IsDebugDirective } from './directives/is-debug.directive';
import { IsSleepCountryDirective } from './directives/is-sleep-country.directive';

@NgModule({
    declarations: [
        TrackEventDirective,
        IsDebugDirective,
        IsSleepCountryDirective
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        TrackEventDirective,
        IsDebugDirective,
        IsSleepCountryDirective
    ]
})
export class UtilsModule {
}
