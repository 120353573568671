import {
    Answer,
    Assessment,
    AssessmentCategory,
    Question,
} from '@haleo-frontend/data-access/models';
import * as moment from 'moment';
import { AnswerTypeEnum } from '@haleo-frontend/data-access/enums';

export class AssessmentUtils {
    static getAnswerValue(
        questionKey: string,
        questions: Question[],
        answers: Answer[]
    ) {
        const question = questions.find((q) => q.key === questionKey);

        if (question) {
            const answer = answers.find((a) => a.questionId === question.id);
            return answer ? answer.value : null;
        } else {
            return null;
        }
    }

    static getOptionLabel(
        questionKey: string,
        questions: Question[],
        answers: Answer[]
    ) {
        const question = questions.find((q) => q.key === questionKey);
        if (question) {
            const answer = answers.find((a) => a.questionId === question.id);
            return answer ? JSON.parse(question.options)[answer.value] : null;
        } else {
            return null;
        }
    }

    static getAnswerValueMultipleKeys(
        questionKeys: string[],
        questions: Question[],
        answers: Answer[]
    ) {
        const question = questions.find(
            (q) => questionKeys.indexOf(q.key) >= 0
        );

        if (question) {
            const answer = answers.find((a) => a.questionId === question.id);
            return answer ? answer.value : null;
        } else {
            return null;
        }
    }

    static getAccountBasedSteps(assessment: Assessment, account: string) {
        const categories = JSON.parse(assessment.steps);
        const category = categories.find(
            (category: AssessmentCategory) => category.key === account
        );
        return category ? category.steps : null;
    }

    static validateTIB(assessment: Assessment) {
        return AssessmentUtils.getTIB(assessment) < 12 * 60;
    }

    // SD_Q5 - ( SD_Q1 + SD_Q15 )
    static getTIB(assessment: Assessment) {
        const sqQ5Answer = AssessmentUtils.getAnswerValueMultipleKeys(
            ['SD_Q5', 'SD_Q5_SW'],
            assessment.questions,
            assessment.answers
        );
        const sqQ1Answer = AssessmentUtils.getAnswerValueMultipleKeys(
            ['SD_Q1', 'SD_Q1_SW'],
            assessment.questions,
            assessment.answers
        );
        const sqQ15Answer = AssessmentUtils.getAnswerValue(
            'SD_Q15',
            assessment.questions,
            assessment.answers
        );

        let tib = 0;

        if (
            sqQ5Answer !== null &&
            sqQ1Answer !== null &&
            sqQ15Answer !== null
        ) {
            let sqQ5Value = moment.utc(assessment.version + ' ' + sqQ5Answer);
            const outOfBedAt = moment.utc(
                assessment.version + ' ' + sqQ1Answer
            );

            if (!sqQ5Value.isBefore(outOfBedAt)) {
                sqQ5Value = sqQ5Value.subtract(1, 'days');
            }

            tib = outOfBedAt
                .add(sqQ15Answer, 'minutes')
                .diff(sqQ5Value, 'minutes');
        }

        return tib;
    }

    // SD_Q6 + SD_Q7.1_NP + SD_Q7.5_NP + SD_Q15
    static getTWT(assessment: Assessment) {
        const sqQ6Answer =
            AssessmentUtils.getAnswerValue(
                'SD_Q6',
                assessment.questions,
                assessment.answers
            ) || 0;
        const sqQ71Answer =
            AssessmentUtils.getAnswerValueMultipleKeys(
                ['SD_Q7.1', 'SD_Q7.1_NP'],
                assessment.questions,
                assessment.answers
            ) || 0;
        const sqQ75Value =
            AssessmentUtils.getAnswerValue(
                'SD_Q7.5_NP',
                assessment.questions,
                assessment.answers
            ) || 0;
        const sqQ15Answer =
            AssessmentUtils.getAnswerValue(
                'SD_Q15',
                assessment.questions,
                assessment.answers
            ) || 0;

        return (
            parseInt(sqQ6Answer) +
            parseInt(sqQ71Answer) +
            parseInt(sqQ75Value) +
            parseInt(sqQ15Answer)
        );
    }

    // 1_TIB - 2_ TWT (TWT can be 0)
    static getTST(assessment: Assessment) {
        const twt = AssessmentUtils.getTWT(assessment) || 0;
        const tib = AssessmentUtils.getTIB(assessment);

        if (tib) {
            return tib - twt;
        }

        return null;
    }

    static validateNegativeTSTCheck(assessment: Assessment) {
        const tst = AssessmentUtils.getTST(assessment);
        return !tst || tst >= 0;
    }

    static insertDynamicQuestions(
        assessment: Assessment,
        current: Question,
        resumeWith: string
    ) {
        if (current) {
            const dynamicQuestions = assessment.questions.filter(
                (question) =>
                    question.nextStep === null &&
                    question.answerType !== AnswerTypeEnum.calculated
            );

            dynamicQuestions.map((question, index) => {
                if (index === 0 && current?.nextStep) {
                    current.nextStep = JSON.stringify({
                        next: question.key,
                        type: 'question',
                    });
                }

                if (dynamicQuestions.length > index + 1) {
                    question.nextStep = JSON.stringify({
                        next: dynamicQuestions[index + 1].key,
                        type: 'question',
                    });
                } else {
                    question.nextStep = JSON.stringify({
                        next: resumeWith,
                        type: 'question',
                    });
                }

                return question;
            });

            // Rebuild questions list by replacing current and dynamic questions with the one we have edited
            const baseQuestions = assessment.questions.filter(
                (q) => q.nextStep !== null && q.key !== current?.key
            );

            if (dynamicQuestions.length > 0) {
                baseQuestions.push(current);
                baseQuestions.concat(dynamicQuestions);
            } else {
                current.nextStep = JSON.stringify({
                    next: resumeWith,
                    type: 'question',
                });
                baseQuestions.push(current);
            }

            return baseQuestions;
        }

        return null;
    }

    static convertPoundsToKg(val: any) {
        return Math.round((val / 2.205) * 100) / 100;
    }

    static convertFeetToCentimeter(val: any) {
        return Math.round(val * 30.48 * 100) / 100;
    }

    static convertKgToPounds(val: any) {
        return parseFloat((val * 2.205).toString()).toFixed(2);
    }

    static convertCentimeterToFeet(val: any) {
        return parseFloat((val / 30.48).toString()).toFixed(2);
    }

    /**
     *    Objective: Build the next nodes for each question
     *    - If a question is used to evaluate the condition then it's a dependency node
     *    - If a question is a dependency node, we add every answer option to key e.i OSA_Q8-0, OSA_Q8-1, OSA_Q8-2
     *    - Otherwise, the key is OSA_8-X
     *    - The suffix is needed to evaluate the condition
     */
    static buildGraph(jsonStep: any, questions: any) {
        const steps = JSON.parse(jsonStep);
        const dependencies = new Set();
        let data = steps[0].steps;

        const questionLookUp = questions.reduce((map: any, question: any) => {
            map[question.key] = {
                options: question.options,
                answerType: question.answerType,
                conditions: question.conditions,
            };
            return map;
        }, {});

        for (let i = 0; i < data.length; i++) {
            // find all edges
            const nodes = new Set();

            if (data[i].next) {
                nodes.add(data[i].next);
            }

            if (data[i].defaultNext) {
                nodes.add(data[i].defaultNext);
            }

            data[i].conditions?.forEach((cond: any) => {
                nodes.add(cond['next']);
            });

            data[i].nodes = Array.from(nodes);

            const question = questionLookUp[data[i].question];
            data[i].answerType = question.answerType;

            // find dependency nodes
            if (data[i].type == 'conditional_question') {
                dependencies.add(data[i].question);
                question.conditions?.forEach((cond: any) => {
                    if (cond.referAnswer) {
                        dependencies.add(cond.referAnswer);
                    }
                });
            }

            // get answer option for conditional question
            if (
                question.answerType === 'radio' ||
                question.answerType === 'checkbox'
            ) {
                const options = JSON.parse(question.options);
                data[i].options = Object.keys(options.options || options);
            }
        }

        // build edge nodes with suffix
        for (let i = 0; i < data.length; i++) {
            const nodes = new Set();

            data[i].nodes.forEach((node: string) => {
                if (dependencies.has(node)) {
                    const question = data.find((q: any) => q.question == node);
                    question.options.forEach((option: string) => {
                        nodes.add(node + '-' + option);
                    });
                } else {
                    nodes.add(node + '-' + 'X');
                }
            });

            data[i].nodes = Array.from(nodes);
        }

        data = data.reduce((map: any, question: any) => {
            map[question.question] = { ...question };
            return map;
        }, {});

        return data;
    }

    /***
     * Using Breath First Search to find the shortest path from any question to the last question
     * @param graph: assessment steps with nodes added
     * @param start: question key which is the start of the path
     */
    static findShortestPath(graph: any, start: string) {
        const startKey = start + '-X';
        const queue = [startKey];
        const visited = new Set();
        const parent: any = {};
        const distance: any = {};
        distance[startKey] = 1;

        while (queue && queue.length > 0) {
            const current: any = queue.shift();
            const [key, answer] = current.split('-');
            visited.add(current);

            const question = graph[key];

            if (question?.type == 'thankYou') {
                return distance[current];
            }

            // validate conditions based on previous question or its answer
            if (question?.type == 'conditional_question') {
                // retrieve all nodes to get to this node
                const questions = this.buildPath(parent, startKey, current);
                let next = question.defaultNext;

                question.conditions.forEach((condition: any) => {
                    if (condition.referAnswer) {
                        if (questions) {
                            const data = questions.find((key) =>
                                key.includes(condition.referAnswer)
                            );
                            const answer = data?.split('-')[0];
                            if (this.evaluate(condition, answer)) {
                                next = condition.next as any;
                            }
                        }
                    } else if (this.evaluate(condition, answer)) {
                        next = condition.next as any;
                    }
                });

                question?.nodes.forEach((node: string) => {
                    // only add the node that the condition validated to be true in the queue
                    if (!visited.has(node) && node.includes(next)) {
                        queue.push(node);
                        distance[node] = distance[current] + 1;
                        parent[node] = current;
                    }
                });
            } else {
                // add the nodes into the queue directly
                question?.nodes.forEach((node: string) => {
                    if (!visited.has(node)) {
                        queue.push(node);
                        distance[node] = distance[current] + 1;
                        parent[node] = current;
                    }
                });
            }
        }
    }

    static buildPath(path: any, start: string, end: string) {
        const result: string[] = [end];
        while (result && result[result.length - 1] !== start) {
            result.push(path[result[result.length - 1]]);
        }
        return result.reverse();
    }

    private static evaluate(condition: any, value: any) {
        return eval(`"${condition.leftValue}"
        ${condition.operator}
        "${value}"`);
    }
}
