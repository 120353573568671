import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PickerController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as countries from 'i18n-iso-countries';
import { AppService } from '@haleo-frontend/utils';

@Component({
    selector: 'app-country-list',
    templateUrl: 'country-list.component.html',
    styleUrls: ['country-list.component.scss']
})
export class CountryListComponent implements OnInit {

    @Input() countryCode: string;
    @Input() localeShort: string;
    @Input() placeholder: string;
    @Output() changed: EventEmitter<string>;

    countryList: any;
    appKey: string;

    constructor(private pickerCtrl: PickerController,
                private translateService: TranslateService,
                private appService: AppService) {
        this.changed = new EventEmitter<string>();
        this.appKey = this.appService.getAppKey();
    }

    ngOnInit(): void {
        countries.registerLocale(require('i18n-iso-countries/langs/' + this.localeShort + '.json'));
        this.countryList = countries.getNames(this.localeShort);
    }

    async select() {

        const options = Object.keys(this.countryList)
            .map(code => ({
                text: this.countryList[code],
                value: code
            }));

        // Put the USA and Canada first
        const usIndex = options.findIndex(option => option.value === 'US');

        if (usIndex >= 0) {
            const us = options[usIndex];
            options.splice(usIndex, 1);
            options.unshift(us);

            const canadaIndex = options.findIndex(option => option.value === 'CA');
            const canada = options[canadaIndex];
            options.splice(canadaIndex, 1);
            options.unshift(canada);
        }

        // Bug fix: impossible to select first value
        options.unshift({text: '', value: ''});

        const config = {
            buttons: [{
                text: this.translateService.instant('cancel'),
                role: 'cancel'
            }, {text: this.translateService.instant('done')}],
            columns: [{
                name: 'data',
                options: options,
                selectedIndex: options.findIndex(option => option.value === this.countryCode)
            }]
        };

        const picker = await this.pickerCtrl.create(config);
        await picker.present();
        await picker.onDidDismiss();

        const col = await picker.getColumn('data');

        if (col && col.selectedIndex) {

            const selectedOption: any = col.options[col.selectedIndex];
            this.changed.emit(selectedOption.value);
        }
    }
}
