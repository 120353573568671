import { Pipe, PipeTransform } from '@angular/core';
import { CountryUtils } from '@haleo-frontend/utils';

@Pipe({name: 'province'})
export class ProvincePipe implements PipeTransform {

    transform(provinceCode: string, countryCode: string, locale: string): string {

        let provinceList: any;
        switch (countryCode) {
            case 'CA' :
                provinceList = (<any>CountryUtils.provinces)[locale];
                break;
            case 'US' :
                provinceList = CountryUtils.states;
        }

        if (provinceList && provinceList[provinceCode]) {
            return provinceList[provinceCode];
        } else {
            return '';
        }
    }
}
