import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentUtils } from '@haleo-frontend/utils';

import { Config } from '../models/config.model';

@Injectable({
    providedIn: 'root'
})
export class ConfigService implements Resolve<any> {

    private config: Config | null;
    private loading: boolean;
    private configSubject: ReplaySubject<Config>;

    constructor(private http: HttpClient) {
        this.configSubject = new ReplaySubject<Config>();
    }

    resolve(): Observable<Config> {

        if (this.config || this.loading) {
            return this.configSubject.asObservable();
        } else {

            this.loading = true;
            return this.get()
                .pipe(map(config => {
                    this.config = config;
                    this.configSubject.next(this.config);
                    this.configSubject.complete();
                    this.loading = false;
                    return this.config;
                }));
        }
    }

    reset() {
        this.configSubject = new ReplaySubject<Config>();
        this.config = null;
    }

    private get(): Observable<Config> {
        return this.http.get<Config>(EnvironmentUtils.env.api + 'configs');
    }
}
