import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export class TranslateHttpLoaderService implements TranslateLoader {

    constructor(private http: HttpClient, public resources: { prefix: string, suffix: string, type: string }[]) {
    }

    public getTranslation(lang: string): any {
        return forkJoin(this.resources.map(config => {

            if (config.type === 'frontitude') {

                let frontitudeLang = lang;

                if (lang === 'en-ca') {
                    frontitudeLang = '';
                } else {
                    const locale = frontitudeLang.substr(0, 2);
                    const country = frontitudeLang.substr(3).toUpperCase();
                    frontitudeLang = `-${locale}_${country}`;
                }

                return this.http.get(`${config.prefix}${frontitudeLang}${config.suffix}`);
            } else {
                return this.http.get(`${config.prefix}${lang}${config.suffix}`);
            }
        }))
            .pipe(map(response => response.reduce((a, b) => Object.assign(a, b))));
    }
}
