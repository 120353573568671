import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EnvironmentUtils } from '@haleo-frontend/utils';

import { AppModule } from './app/app.module';

if (EnvironmentUtils.env.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
