import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppService } from '@haleo-frontend/utils';

@Directive({
    selector: '[isSleepCountry]'
})
export class IsSleepCountryDirective {

    constructor(private viewContainerRef: ViewContainerRef,
                private templateRef: TemplateRef<any>,
                private appService: AppService) {
    }

    @Input()
    set isSleepCountry(status: boolean) {

        this.viewContainerRef.clear();

        const isSleepCountry = this.appService.isSleepCountry()

        if (status && isSleepCountry) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }

        if (!status && !isSleepCountry) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
