import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable()
export class HttpBroadcastInterceptor implements HttpInterceptor {

    constructor(private appService: AppService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

        if ((request.url.indexOf('authenticate/register') >= 0 && this.appService.isSelfGuided()) || request.url.indexOf('async=1') >= 0) {

            const modifiedRequest = request.clone({
                headers: request.headers.set('X-Broadcast', '1'),
            });

            return next.handle(modifiedRequest);
        } else {
            return next.handle(request);
        }
    }
}
